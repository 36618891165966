<template>
  <div class="detail-transfer-stock-deleted">
        <KTCodePreview v-bind:title="'Thông tin phiếu chuyển kho đã xóa'">
          <template v-slot:preview>
            <div class="col-12 row" style="padding-left: 0px">
              <b-form-group
                class="col-3"
              >
              <label>Mã phiếu:</label>
                <p>{{ codeStockBill }}</p>
              </b-form-group>
              <b-form-group
                class="col-3"
              >
              <label>Ngày tạo:</label>
                <p>{{ dateCreate }}</p>
              </b-form-group>

              <b-form-group
                class="col-3 required-control"
              >
              <label>Ngày chuyển kho:</label>
                <p>{{ $v.form.dateImport.$model }}</p>
              </b-form-group>
            </div>

            <div class="col-12 row" style="padding-left: 0px">
              <b-form-group
                class="col-3 required-control"
                :disabled="!isEdit"
              >
              <label>Kho nguồn:</label>
                <b-form-select
                  :disabled="true"
                  class="mt-2 select-style"
                  v-model="$v.form.sourceStoreSelected.$model"
                  :options="listInventories"
                  size="sm"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >Chọn kho nguồn</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                class="col-3 required-control"
                :disabled="!isEdit"
              >
              <label>Kho đích:</label>
                <b-form-select
                  :disabled="true"
                  class="mt-2 select-style"
                  v-model="$v.form.desStoreSelected.$model"
                  :options="listInventories"
                  size="sm"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >Chọn kho đích</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                class="col-3"
              >
              <label>Tổng tiền:</label>
                <p>{{ convertPrice(totalPrice) }}</p>
              </b-form-group>
            </div>
            <div class="col-12 row" style="padding-left: 0px">
              <b-form-group
                :disabled="!isEdit"
                class="col-6"
              >
              <label>Nội dung:</label>
                <b-form-textarea
                  size="sm"
                  v-model="notes"
                  :placeholder="'Thêm nội dung...'"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                label-class="font-weight-bolder"
                class="col-3"
              >
              <label>Người tạo:</label>
                <p>{{ createBy }}</p>
              </b-form-group>
            </div>
            <span class="font-weight-bolder">Danh sách sản phẩm:</span>
            <table
              class="
                table table-bordered table-vertical-center table-hover
                col-12
                mt-4
              "
            >
              <thead>
                <tr>
                  <th scope="col" class="title-center" style="width: 20%">
                    Tên sản phẩm
                  </th>
                  <th scope="col" class="title-center" style="width: 5%">
                    Tồn
                  </th>
                  <th scope="col" class="title-center" style="width: 19%">
                    IMEI
                  </th>
                  <th scope="col" class="title-center" style="width: 14%">
                    Số lượng
                  </th>
                  <th
                    scope="col"
                    class="title-center"
                    style="width: 20%"
                    v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
                  >
                    Đơn giá
                  </th>
                  
                  <th
                    scope="col"
                    class="title-center"
                    style="width: 15%"
                    v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
                  >
                    Thành tiền
                  </th>
                </tr>
              </thead>
              <tr v-if="listProductStock.length > 0">
                <td
                  :colspan="
                    checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW') ? 4 : 3
                  "
                  class="tdTextAlignPrice"
                >
                  Tổng
                </td>
                <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
                <td
                  class="tdTextAlignPrice"
                  v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
                >
                  {{ convertPrice(totalPrice) }}
                </td>
              </tr>
              <tbody v-for="item in listProductStock" :key="item.id">
                <TransferStock
                  v-bind:productItem="item"
                  v-bind:status="status"
                  v-bind:editState="false"
                  v-bind:canViewOriginalPrice="
                    checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
                  "
                />
              </tbody>
              <tr>
                <td
                  :colspan="
                    checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW') ? 4 : 3
                  "
                  class="tdTextAlignPrice"
                >
                  Tổng
                </td>
                <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
                <td
                  class="tdTextAlignPrice"
                  v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
                >
                  {{ convertPrice(totalPrice) }}
                </td>
              </tr>
            </table>
          </template>
          <template v-slot:foot>
            <router-link to="/transfer-stock-deleted" tag="button">
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Trở về</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss'; 

.detail-transfer-stock-deleted {
  .title-center {
    text-align: center;
  }

  .autosuggest__results ul {
    list-style: none;
    cursor: pointer;
    margin-bottom: 0;
  }

  .autosuggest__results {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    width: 100%;
    border: 1px solid #e0e0e0;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  #autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(1.35em + 1.1rem + 2px);
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.28rem;
  }

  #autosuggest__input:focus {
    outline: none !important;
    border: 1px solid #92c5f9;
  }

  .autosuggest__results-item:hover {
    background-color: #ddd;
  }

  .autosuggest__results ul li {
    padding: 8px;
  }

  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }

  .productCode:hover {
    text-decoration: underline;
  }
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import TransferStock from '@/view/components/TransferStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import { unMaskPrice } from '@/utils/common';
import localData from '@/utils/saveDataToLocal';
import { convertPrice } from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      codeStockBill: '',
      dateCreate: '',
      listInventories: [],
      totalPrice: 0,
      listProduct: [],
      listProductSearch: [],
      count: 0,
      notes: '',
      idStock: '',
      form: {
        sourceStoreSelected: null,
        desStoreSelected: null,
        dateImport: '',
      },
      isNew: true,
      createBy: '',
      listProductStock: [],
      selectedClassProduct: null,
      totalQuantity: 0,
      isSearching: false,
      isEdit: false,
      status: 3
    };
  },
  validations: {
    form: {
      sourceStoreSelected: { required },
      desStoreSelected: { required },
      dateImport: { required },
    },
  },
  components: {
    KTCodePreview,
    TransferStock,
  },
  created() {
    this.fetchStore();
    this.getStockById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu chuyển kho đã xóa', route: '/transfer-stock-deleted' },
      { title: 'Thông tin phiếu chuyển kho đã xóa' },
    ]);
  },
  methods: {
    convertPrice,
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    checkTotalMoney() {
      this.totalPrice = 0;
      this.totalQuantity = 0;
      this.listProductStock.forEach((element) => {
        let total = element.quantity * unMaskPrice(element.price);
        this.totalPrice += total;
        this.totalQuantity += parseInt(element.quantity);
      });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
      });
    },
    getStockById() {
      let id = this.$route.query.id;
      ApiService.setHeader();
      ApiService.get(`stocks/transfer-stock/${id}`).then(({ data }) => {
        let dataRes = data.data;
        this.codeStockBill = dataRes.code;
        this.dateCreate = moment(String(dataRes.createdAt)).format(
          'HH:mm DD/MM/YYYY'
        );
        this.$v.form.dateImport.$model = moment(
          String(dataRes.transferDate)
        ).format('DD-MM-YYYY');
        this.$v.form.sourceStoreSelected.$model = dataRes.sourceStoreId;
        this.$v.form.desStoreSelected.$model = dataRes.desStoreId;
        this.totalPrice = dataRes.totalAmount;
        this.notes = dataRes.description;
        this.createBy = dataRes.createdBy;
        this.status = dataRes.status;
        this.idStock = dataRes.id;

        dataRes.listDetail.forEach((element) => {
          this.count++;
          let dataItem = {
            id: element.id,
            name: element.productName,
            productCode: element.productCode,
            barCode: element.barCode,
            IMEI: element.productImei.replace(', ', '\n'),
            proId: element.productId,
            price: element.unitPrice,
            quantity: element.quantity,
            totalPrice: element.totalAmount,
            productType: element.productType,
            totalQuantityInStock:
              element.totalQuantityInStock != null
                ? element.totalQuantityInStock
                : 0,
          };
          this.listProductStock.push(dataItem);
        });
        this.checkTotalMoney();
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
